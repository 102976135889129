import React from 'react';
import { useDidMount } from '../../hooks/life-cycle';
import { ApiManager } from '../../managers/api-manager';
import { useDispatch } from 'react-redux';
import { envAction } from '../../slices/env-slice';
import { dialogAction } from '../../slices/dialog-slice';
import { serverEnvAction } from '../../slices/server-env-slice';
import { ADMIN_HEADER_NAME, COMPANY_HEADER_NAME } from '../../models/foundation';
import { useAppDispatch } from '../../app/hooks';

export const Initializer = () => {
  // const { type } = props;
  const dispatch = useAppDispatch();
  useDidMount(() => {

    // - エラーダイアログ表示処理の登録 -
    ApiManager.onError = (e) => {
      const statusCode = e?.header.status_code ?? '';
      let message = ['Unexpected error occurred !!'];
      if (
        e.body
        && e.body.data.errors
      ) {
        message = Object.values(e.body.data.errors).flat() as string[];
      } else if (
        e.header
        && e.header.messages
        && e.header.messages.message
      ) {
        message = Object.values(e.header.messages);
      }
      dispatch(dialogAction.pushMessage({
        title: `エラー${statusCode ? ` (CODE : ${statusCode})` : ''}`,
        message,
        buttons: [
          {
            label: 'CLOSE',
            callback: () => {
              dispatch(dialogAction.pop());
            },
          },
        ],
      }));
    };
    // - env.json の取得 -
    const cache = String(new Date().getTime());
    fetch(`./server-env/mode.json?cache=${cache}`)
      .then((res) => {
        if (res.ok) {
          return res;
        }
        throw res;
      })
      .then((res) => res.json())
      .then((json) => {
        const kind = json.type ?? '';
        if (!['admin', 'company', 'data'].includes(kind)) {
          throw new Error('不正な初期化です。')
        };
        const type = kind === 'company' ? 'company' : 'admin'
        dispatch(serverEnvAction.set({
          type: type,
          mode: json?.mode ?? '',
          adminUrl: json?.adminUrl ?? '',
          companyUrl: json?.companyUrl ?? '',
          manuelUrl: json?.manuelUrl ?? '',
          startGuideUrl: json?.startGuideUrl ?? '',
          adminGuideUrl: json?.adminGuideUrl ?? '',
          rulesUrl: json?.rulesUrl ?? '',
          kind,
          isInitialize: true,
        }));
        switch (json.mode) {
          case 'local':
            if (type === 'admin') {
              document.title = `${ADMIN_HEADER_NAME}-ローカル`;
              break;
            } else if (type === 'company') {
              document.title = `${COMPANY_HEADER_NAME}-ローカル`;
              break;
            } else {
              document.title = '';
              break;
            }
          case 'develop':
            if (type === 'admin') {
              document.title = `${ADMIN_HEADER_NAME}-開発`;
              break;
            } else if (type === 'company') {
              document.title = `${COMPANY_HEADER_NAME}-開発`;
              break;
            } else {
              document.title = '';
              break;
            }
          case 'develop2':
            if (type === 'admin') {
              document.title = `${ADMIN_HEADER_NAME}-ステージング`;
              break;
            } else if (type === 'company') {
              document.title = `${COMPANY_HEADER_NAME}-ステージング`;
              break;
            } else {
              document.title = '';
              break;
            }
          case 'staging':
            if (type === 'admin') {
              document.title = `${ADMIN_HEADER_NAME}-ステージング`;
              break;
            } else if (type === 'company') {
              document.title = `${COMPANY_HEADER_NAME}-ステージング`;
              break;
            } else {
              document.title = '';
              break;
            }
          case 'production':
            if (type === 'admin') {
              document.title = `${ADMIN_HEADER_NAME}`;
              break;
            } else if (type === 'company') {
              document.title = `${COMPANY_HEADER_NAME}`;
              break;
            } else {
              document.title = '';
              break;
            }
          default :
            document.title = '';
            break;
        }
        fetch(`./env.json?cache=${cache}`)
          .then((res) => {
            if (res.ok) {
              return res;
            }
            throw res;
          })
          .then((res) => res.json())
          .then((json) => {
            dispatch(envAction.set({
              version: json.version,
              build: json.build,
            }));
            document.title += ` ${json.version}`;
          });
      })
      .catch((err) => {
        console.error('README にしたがって、mode.json を設置してください');
      });
  });
  return (
    <></>
  );
};
