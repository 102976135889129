/** システムID */
const systemId = {
  /** 1: ISP管理 */
  ispWeb: 1,
  /** 2: 企業WEB管理 */
  ispCompanyWeb: 2,
  /** 3: IDMS */
  idms: 3,
  /** 4: CADECT */
  cadect: 4,
};

/** アクションID */
const actionId = {
  /** 1 押下 */
  click: 1,
  /** 2 選択 */
  select: 2,
  /** 3 一覧（検索) */
  search: 3,
  /** 4 表示 */
  display: 4,
  /** 5 実行 */
  run: 5,
  /** 6 ドロップ */
  drop: 6,
  /** 7 解除 */
  release: 7,
  /** 8 保存 */
  save: 8,
  /** 9 起動 */
  start: 9,
  /** 10 終了 */
  end: 10,
  /** 11 発行 */
  publish: 11,
  /** 12 認証 */
  authentication: 12,
  /** 13 送信 */
  submit: 13,
  /** 14 強制終了 */
  shutdown: 14,
};

/** シーンID */
const sceneId = {
  /** 1: 認証 */
  certification: 1,
  /** 2: ISPポータル */
  ispPortal: 2,
  /** 3: 企業ポータル */
  companyWebPortal: 3,
  /** 4: 企業管理 */
  companyManagement: 4,
  /** 5: 組織管理 */
  organizationManagement: 5,
  /** 6: 企業ユーザー管理 */
  companyUserManagement: 6,
  /** 7: M-LOOPユーザー管理 */
  ispUserManagement: 7,
  /** 8: 設定メニュー */
  settingMenu: 8,
  /** 9: データ管理 */
  dataManagement: 9,
  /** 10: 依頼管理 */
  requestManagement: 10,
  /** 11: ソフトウェアランチャー */
  softwareLauncher: 11,
  /** 12: ヘルプ */
  help: 12,
  /** 13: ポータル */
  portal: 13,
  /** 14: インフォメーション管理 */
  informationManagement: 14,
  /** 15: ログ管理 */
  logManagement: 15,
  /** 16: 企業操作ログ */
  companyOperationLog: 16,
  /** 17: 物件管理 */
  articleManagement: 17,
  /** 18: 権限管理 */
  authManagement: 18,
  /** 19: 依頼設定 */
  requestSetting: 19,
};

/** サブシーンID */
const subSceneId = {
  /** 1: 新規 */
  new: 1,
  /** 2: 編集 */
  edit: 2,
  /** 3: 削除 */
  delete: 3,
  /** 4: 復活 */
  restore: 4,
  /** 5: DL */
  dl: 5,
  /** 6: AP */
  ap: 6,
  /** 7: 更新 */
  update: 7,
  /** 8: OK */
  ok: 8,
  /** 9: キャンセル */
  cancel: 9,
  /** 10: 追加 */
  add: 10,
  /** 11: 複製 */
  copy: 11,
  /** 12: 表示設定 */
  displaySetting: 12,
  /** 13: パスワード変更 */
  changePassword: 13,
  /** 14: アカウント切替 */
  changeAccount: 14,
  /** 15: 企業設定画面 */
  companySetting: 15,
  /** 16: 個人設定 */
  personalSetting: 16,
  /** 17: 物件 */
  article: 17,
  /** 18: プラン */
  plan: 18,
  /** 19: 依頼 */
  request: 19,
  /** 20: 作業者 */
  worker: 20,
  /** 21: リスト設定 */
  listSetting: 21,
  /** 22: ファイル */
  file: 22,
  /** 23: CADECT */
  cadect: 23,
  /** 24: STABRO */
  stabro: 24,
  /** 25: インフォメーション */
  information: 25,
  /** 26: 連絡 */
  contact: 26,
  /** 27: パスワード忘れ */
  forgetPassword: 27,
  /** 28: アップロード */
  upload: 28,
  /** 29: ダウンロード */
  download: 29,
  /** 30: ログイン */
  login: 30,
  /** 31: ログアウト */
  logout: 31,
  /** 32: 宛先 */
  address: 32,
  /** 33: 送信 */
  sent: 33,
  /** 34: 組織 */
  organization: 34,
  /** 35: メンバー */
  member: 35,
  /** 36: 依頼物件/過去物件 */
  RequestArticleOrPastArticle: 36,
  /** 37: 詳細 */
  detail: 37,
  /** 38: データ管理 */
  dataManagement: 38,
  /** 39: 入力 */
  input: 39,
  /** 40: 確認 */
  confirm: 40,
  /** 41: 戻る */
  return: 41,
  /** 42: 登録 */
  entry: 42,
  /** 43: 招待 */
  invite: 43,
  /** 44: 状況一覧 */
  statusList: 44,
  /** 45: 組織管理 */
  organizationManagement: 45,
  /** 46: 企業ユーザー管理 */
  companyUserManagement: 46,
  /** 47: 企業ポータル */
  companyPortal: 47,
  /** 48: 再招待 */
  reInvite: 48,
  /** 49: 利用再開 */
  reUse: 49,
  /** 50: パスワード再設定 */
  reSettingPassword: 50,
  /** 51: 表示名 */
  displayName: 51,
  /** 52: アイコン */
  icon: 52,
  /** 53: Revit */
  revit: 53,
  /** 54: 通知先設定 */
  notificationTargetSetting: 54,
  /** 55: 対象ユーザー */
  targetUser: 55,
  /** 56: 通知ユーザー */
  notificationUser: 56,
  /** 57: テンプレートから指定 */
  templateSelect: 57,
  /** 58: テンプレート */
  template: 58,
  /** 59: 上書保存 */
  overwritingSave: 59,
  /** 60: csv取り込み */
  csvImport: 60,
  /** 61: 再指定 */
  reSelect: 61,
  /** 62: 依頼（受信） */
  requestReceiving: 62,
  /** 63: 依頼（送信） */
  requestSending: 63,
  /** 64: 依頼キャンセル */
  requestCancel: 64,
  /** 65: エラー報告 */
  errorReport: 65,
  /** 66: A-repo4建築 */
  aRepo4Build: 66,
  /** 67: A-repo4住宅 */
  aRepo4Housing: 67,
  /** 68: SeACD */
  seACD: 68,
  /** 69: M-Draw */
  mDraw: 69,
  /** 70: DiPS */
  dips: 70,
  /** 71: AutoCAD */
  autoCAD: 71,
  /** 72: アプリケーション設定 */
  applicationSetting: 72,
  /** 73: 合併 */
  marge: 73,
  /** 74: 変更 */
  modify: 74,
  /** 75: 移動 */
  move: 75,
  /** 76: 要確認 */
  check: 76,
  /** 77: コメント */
  comment: 77,
  /** 78: ユーザー追加 */
  userAdd: 78,
  /** 79: 一括 */
  batch: 79,
  /** 92: フォーム */
  form: 92,
  /** 03: 直接入力 */
  direct: 93,
  /** 94: 個人宛 */
  persons: 94,
  /** 95: 組織宛 */
  organizations: 95,
  /** 96: 設定 */
  setting: 96,
  /** 901: CADECT（竹中様）*/
  takenaka: 901,
  /** 902: 長谷工専用 */
  hasegawa: 902,
};

export const apiLog = {
  systemId,
  actionId,
  sceneId,
  subSceneId,
};