import { useCallback } from 'react';
import { push } from '@lagunovsky/redux-react-router';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

type BreadcrumbListProps = {
  breadcrumbList: {
    label: string,
    route: string,
  }[],
};
// - ISP管理ログ登録用データ -
const checkIspLabel = (label: string) => {
  switch (label) {
    case 'HOME':
      return 'portalClick';
    case '企業一覧':
      return 'companyListClick';
    case '新規企業登録':
      return 'companyAddClick';
    case '企業登録変更':
      return 'companyEditClick';
    case '状況一覧':
      return 'companyStatusClick';
    case '組織管理':
      return 'organizationManagerClick';
    case '企業ユーザー管理':
      return 'companyUserListClick';
    case 'ユーザー新規登録':
      return 'companyUserAddClick';
    case 'ユーザー情報編集':
      return 'companyUserEditClick';
    case 'M-LOOPユーザー一覧':
      return 'ispUserListClick';
    case 'M-LOOPユーザー登録':
      return 'ispUserAddClick';
    case 'M-LOOPユーザー更新':
      return 'ispUserEditClick';
      // -- 企業編集（動的に決まるため） --
    default:
      return 'companyDetailClick';
  }
};
// - 企業管理ログ登録用データ -
const checkCompanyLabel = (label: string) => {
  switch (label) {
    case '組織管理':
      return 'organizationManagerClick';
    case '企業ユーザー管理':
      return 'companyUserListClick';
    case 'ユーザー新規登録':
      return 'companyUserAddClick';
    case 'ユーザー情報編集':
      return 'companyUserEditClick';
    case 'M-LOOPユーザー一覧':
      return 'ispUserListClick';
    case 'M-LOOPユーザー登録':
      return 'ispUserAddClick';
    case 'M-LOOPユーザー更新':
      return 'ispUserEditClick';
    default:
      return '';
  }
};

export const BreadcrumbList = (props: BreadcrumbListProps) => {
  // - props -
  const { breadcrumbList } = props;
  // - selector -
  const { type } = useAppSelector((state) => state.serverEnv);
  // - dispatch -
  const dispatch = useAppDispatch();
  // - callback -
  const handlerClickBreadcrumb = useCallback((v: BreadcrumbListProps['breadcrumbList'][0]) => {
    const id = type === 'admin'
      ? checkIspLabel(v.label)
      : checkCompanyLabel(v.label);
    id ?
      IspLogOperation(id, () => {
        dispatch(push(v.route));
      })
      : dispatch(push(v.route));
  }, [type]);
  return (
    <div className="breadcrumb">
      {breadcrumbList.map((v, i) => (
        <div key={`breadcrumb-list-data_${i}`}>
          {i > 0 ? ' \u00a0> ' : ''}
          {breadcrumbList.length - 1 === i ? (
            v.label
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlerClickBreadcrumb(v);
              }}
            >
              {v.label}
            </a>
          )}
        </div>
      ))}
    </div>
  );
};
