import Log from './log';
import EventEmitter from 'eventemitter3';
import { EventType, IAPIInfo } from '../api';

type Method = 'GET' | 'POST' | 'PUT';

export type RequestParameter = { [key: string]: string | number | boolean | File | string[] | object[] | null | number[]  };

export default class Connection extends EventEmitter<EventType> {

  private apiInfo: IAPIInfo = {
    count: 0,
  };

  private defaultInit: RequestInit = {
    credentials: 'include',
  };

  private csrfToken: string = '';

  private defaultOptions = {
    timeoutMS: 30000,
  };

  constructor() {
    super();
  }

  get<ResponseType>(
    url: string,
    param: RequestParameter,
    noError: boolean = false,
    noNetworkError: boolean = false,
  ): Promise<ResponseType> {
    const searchParam = this.createSearchParams(param);
    return this.postRequest<ResponseType>({
      url: searchParam ? `${url}?${searchParam}` : url,
      init: { ...this.defaultInit },
      requestParameters: param,
    }, noError, this.defaultOptions.timeoutMS, noNetworkError);
  }

  getFile<ResponseType>(
    url: string,
    param: RequestParameter,
    noError: boolean = false,
    timeoutMS: number = this.defaultOptions.timeoutMS,
    isGetFileName: boolean = false,
  ): Promise<ResponseType | Blob> {
    const searchParam = this.createSearchParams(param);
    return this.getFileRequest<ResponseType | Blob>({
      url: searchParam ? `${url}?${searchParam}` : url,
      init: { ...this.defaultInit },
      requestParameters: param,
      isGetFileName,
    }, noError, timeoutMS);
  }

  post<ResponseType>(
    url: string,
    param: RequestParameter,
    noError: boolean = false,
    timeoutMS: number = this.defaultOptions.timeoutMS
  ) {
    const formData = this.createFormData(param);
    return this.postRequest<ResponseType>({
      url,
      init: {
        ...this.defaultInit,
        method: 'POST',
        body: formData,
      },
      requestParameters: param,
    }, noError, timeoutMS);
  }

  put<ResponseType>(url: string, param: RequestParameter, noError: boolean = false) {
    const formData = this.createFormData(param);
    return this.postRequest<ResponseType>({
      url,
      init: {
        ...this.defaultInit,
        method: 'POST',
        body: formData,
      },
      requestParameters: param,
    }, noError);
  }

  delete<ResponseType>(url: string, param: RequestParameter, noError: boolean = false) {
    const searchParam = this.createSearchParams(param);
    return this.postRequest<ResponseType>({
      url: searchParam ? `${url}?${searchParam}` : url,
      init: {
        ...this.defaultInit,
        method: 'DELETE',
      },
      requestParameters: param,
    }, noError);
  }

  private postRequest<ResponseType>(param: {
    url: string,
    init: RequestInit,
    requestParameters: RequestParameter
  },
  noError: boolean = false,
  timeoutMS: number = this.defaultOptions.timeoutMS,
  noNetworkError: boolean = false): Promise<ResponseType> {
    this.onStart();
    this.emit('start', this.apiInfo);
    const headers = new Headers();
    // | this.csrfTokenに値があって、methodがpost, put, deleteのいづれかの場合は、
    // リクエストのheaderに、tokenを設定
    if (this.csrfToken
      && (param.init.method === 'POST'
      || param.init.method === 'PUT'
      || param.init.method === 'DELETE')) {
      headers.set('X-CSRF-TOKEN', this.csrfToken);
    }
    return this.fetchTimeOut(
      param.url,
      { ...param.init, headers },
      timeoutMS || this.defaultOptions.timeoutMS,
    )
      .then(async (response) => {
        this.refreshCsrfToken(response);
        await Log.onResponse(param.init.method || 'GET', param.url, param.requestParameters, response);
        const json = await response.json();
        if (json?.header?.status_code && json?.header?.status_code === 200) {
          this.emit('success', json);
          this.onFinish();
          this.emit('finish', this.apiInfo);
          return Promise.resolve(json as ResponseType);
        } else if (json.header?.status_code) {
          if (!noError) {
            this.emit('response-error', json);
          }
          this.onFinish();
          this.emit('finish', this.apiInfo);
          return Promise.resolve(json as ResponseType);
        }
        if (!noNetworkError) {
          this.emit('network-error', response);
        }
        this.onFinish();
        this.emit('finish', this.apiInfo);
        throw response;
      })
      .catch((response) => {
        if (!noNetworkError) {
          this.emit('network-error', response);
        }
        this.onFinish();
        this.emit('finish', this.apiInfo);
        throw response;
      });
  }

  private getFileRequest<ResponseType>(param: {
    url: string,
    init: RequestInit,
    requestParameters: RequestParameter,
    isGetFileName: boolean,
  },
  noError: boolean = false,
  timeoutMS: number = this.defaultOptions.timeoutMS): Promise<ResponseType | Blob> {
    this.onStart();
    this.emit('start', this.apiInfo);
    // return fetch(param.url, param.init)
    return this.fetchTimeOut(
      param.url,
      param.init,
      timeoutMS || this.defaultOptions.timeoutMS,
    )
      .then(async (response) => {
        try {
          await Log.onResponse(param.init.method || 'GET', param.url, param.requestParameters, response);
          const responseClone = response.clone();
          const json = await responseClone
            .json()
            .catch(() => {
              console.log('json 変換処理でエラー');
            });
          if (json?.header?.status_code && json?.header?.status_code !== 200) {
            if (!noError) {
              this.emit('response-error', json);
            }
            this.onFinish();
            this.emit('finish', this.apiInfo);
            return Promise.resolve(json);
          }
          const blob = await response.blob();
          this.emit('success', response);
          this.onFinish();
          this.emit('finish', this.apiInfo);
          if (param.isGetFileName) {
            const disposition = response.headers.get('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const regex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/;
              const matches =regex.exec(disposition);
              if (matches != null && matches[1]) {
                const filename = decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''));
                return Promise.resolve({ fileName: filename,  body: blob as Blob });
              }
            }
          }
          return Promise.resolve(blob as Blob);
        } catch (error) {
          this.emit('network-error', error);
          this.onFinish();
          this.emit('finish', this.apiInfo);
          throw error;
        }
      });
  }

  private createSearchParams(param: RequestParameter): string {
    const searchParam = new URLSearchParams();
    const keys = Object.keys(param);
    const values = Object.values(param);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      let value = values[i];
      if (value === undefined || ((typeof value === 'number') && isNaN(value))) {
        continue;
      };
      // bool -> num 変換処理
      if (typeof value === 'boolean') {
        value = value ? 1 : 0;
      }
      // Array 対応
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          value.forEach((v, r) => {
            const tmpKeys = Object.keys(v);
            tmpKeys.forEach((k) => {
              const _key = `${key}[${r}][${k}]`;
              searchParam.append(_key, (v as any)[k]);
            });
          });
        } else {
          value.forEach((v, i) => {
            searchParam.append(`${key}[${i}]`, `${v}`);
          })
        }

      } else {
        searchParam.append(key, String(value));
      }
    }
    return searchParam.toString();
  }

  private createFormData(param: RequestParameter): FormData {
    const formData = new FormData();
    const keys = Object.keys(param);
    const values = Object.values(param);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      let value = values[i];
      if (values[i] === undefined) continue;
      // bool -> num 変換処理
      if (typeof value === 'boolean') {
        value = value ? 1 : 0;
      }
      // Array 対応
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          value.forEach((v, r) => {
            const tmpKeys = Object.keys(v);
            tmpKeys.forEach((k) => {
              const _key = `${key}[${r}][${k}]`;
              formData.append(_key, (v as any)[k]);
            });
          });
        } else {
          value.forEach((v, i) => {
            formData.append(`${key}[${i}]`, `${v}`);
          })
        }
      } else if (value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, String(value));
      }
    }
    return formData;
  }

  private onStart() {
    this.apiInfo.count += 1;
  }

  private onFinish() {
    this.apiInfo.count -= 1;
  }

  /** トークンを取得 */
  private refreshCsrfToken (response: Response): void {
    const csrfToken = response.headers.get('client-security-token');
    if (csrfToken) {
      this.csrfToken = csrfToken;
    }
  }

  private fetchTimeOut(
    input: RequestInfo,
    init?: RequestInit,
    timeOut?: number,
  ): Promise<Response> {
    const TIME_MS = timeOut || 1000;
    const abortController = new AbortController();
    return new Promise((resolve, reject) => {
      const timerId = window.setTimeout(() => {
        abortController.abort();
        reject('タイムアウトが発生しました');
      }, TIME_MS);
      fetch(input, { ...init, signal: abortController.signal })
        .then((res) => resolve(res))
        .catch((res) => reject(res))
        .finally(() => clearTimeout(timerId));
    });
  }
}
