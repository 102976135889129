import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { SortListType, Table } from '../../table/table';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Button } from '../../ui/button/button';
import { Header } from '../../ui/header/header';
import { Input } from '../../ui/input/input';
import { Pager } from '../../ui/pager/pager';
import { SideBar } from '../../ui/sidebar/sidebar';
import iconLink from '../../../assets/images/common/icon_link.svg';
import { Accordion } from '../../ui/accordion/accordion';
import './information-list.scss';
import { DatePicker } from '../../ui/date-picker/date-picker';
import { LabeledForm } from '../../ui/input/labeled-form';
import { Select } from '../../ui/select/select';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { TableElement } from '../../table/table-element';
import { InfoCollections } from '../../../models/info-collections';
import { CompanyBody } from '../../../models/company-data';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { apiCompany, apiAdmin, ApiManager } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import { OrganizationSelectDialog } from '../../dialog/organization-select-dialog';
import { DateFormatter } from '../../../utilities';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import iconMailOpen from '../../../assets/images/common/icon_mail_open.svg';
import iconMailClose from '../../../assets/images/common/icon_mail_close.svg';
import { useAsyncLogOperation } from '../../../hooks/use-async-log-operation';
import { useAppDispatch } from '../../../app/hooks';

const breadcrumbList = (type: 'company' | 'admin') => [
  type === 'company' ? breadcrumbParts.company.home : breadcrumbParts.isp.home,
  type === 'company' ? breadcrumbParts.company.info : breadcrumbParts.isp.ispInfo,
];

export type InfosList = {
  id: string;
  category: string;
  level: string;
  format: JSX.Element;
  title: string;
  creatDate: string;
  name: string;
  status: string;
  startDate: string;
  endDate: string;
  openStatus: string;
};

export type InfosListDate = {
  info_id: string;
  internal_id: number;
  is_mth: number;
  title: string;
  level: number;
  category: number;
  is_url: number;
  url: string;
  notification_start_date: string;
  notification_end_date: string;
  sender_name: string;
  created_at: string;
  updated_at: string;
  created_member_id: string;
  created_display_name: string;
  status: number;
  sent_count: number;
  opened_count: number;
}

type SelectData = {
  key: string,
  label: string,
};

export const InformationList = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useAppDispatch();
  const operation = useAsyncLogOperation();

  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);
  const createRef = useRef<HTMLInputElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  // 一度に表示するデータの件数
  const perData: number = 20;
  /* Hooks */
  const [index, setIndex] = useState(5);
  const [activeSort, setActiveSort] = useState('active');
  const [levelList, setLevelList] = useState<SelectData[]>([]);
  const [categoryList, setCategoryList] = useState<SelectData[]>([]);
  const [level, setLevel] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [freeWord, setFreeWord] = useState<string>('');
  const [startDate, setStartDate] = useState<null | string>('');
  const [endDate, setEndDate] = useState<null | string>('');
  const [createDate, setCreateDate] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [queryFlag, setQueryFlag] = useState(true);

  const baseApi = useMemo(() => apiManger.type === 'admin' ? apiAdmin : apiCompany , [apiManger]);

  /* Callback */
  // - カテゴリー -
  const changeCategory = useCallback((v: string) => {
    setCategory(v);
  }, []);
  // - フリーワード -
  const changeFreeWord = useCallback((v: string) => {
    setFreeWord(v);
  }, [freeWord]);
  // - レベル -
  const changeLevel = useCallback((v: string) => {
    setLevel(v);
  }, []);
  // - 新規登録画面遷移 -
  const onClickAdd = useCallback(() => {
    apiManger.type === 'company' ?
      CompanyLogOperation('informationCreateClick', () => {
        dispatch(push(RoutingPath.companyInfoAdd));
      }) :
      IspLogOperation('informationCreateClick', () => {
        dispatch(push(RoutingPath.adminInfoAdd));
      });
  }, []);

  // - ページネーション -
  // const changePaginate = useCallback((page: number) => {
  //   setCurrentPage(page * perData);
  // }, [currentPage]);

  // - トータルページ -
  const [totalInfo, setTotalInfo] = useState(NaN);

  // ソートキーの命名
  const sortKey = {
    /** id */
    id: 1,
    /** カテゴリ */
    category: 2,
    /** 通知レベル */
    level: 3,
    /** タイトル */
    title: 4,
    /** 作成日 */
    creatDate: 6,
    /** 作成者 */
    name: 5,
    /** 状態 */
    status: 7,
    /** 通知開始日 */
    startDate: 8,
    /** 通知終了日 */
    endDate: 9,
  };
  // 昇降の明示
  const highlowKey = {
    high: 0,
    low: 1,
  };
  const [sortBy, setSortBy] = useState(sortKey.creatDate);
  const [highlow, setHighlow] = useState(highlowKey.low);
  // 仮データリスト
  // const [companyList, setCompanyList] = useState<Api[]>([]);
  const [infoList, setInfoList] = useState<InfosListDate[]>([]);

  /** ソート情報まとめ */
  const sortList: SortListType[] = [
    {
      index: 0, // 何列目
      type: 'up', // 初期の昇降順
      key: sortKey.id, // 何でソートするか
      active: '', // 現在のソートならactive
      callback: () => {
      },
    },
    {
      index: 1,
      type: 'up',
      key: sortKey.category,
      active: '',
      callback: () => {
      },
    },
    {
      index: 2,
      type: 'up',
      key: sortKey.level,
      active: '',
      callback: () => {
      },
    },
    {
      index: 4,
      type: 'up',
      key: sortKey.title,
      active: '',
      callback: () => {
      },
    },
    {
      index: 5,
      type: 'up',
      key: sortKey.creatDate,
      active: '',
      callback: () => {
      },
    },
    {
      index: 6,
      type: 'up',
      key: sortKey.name,
      active: '',
      callback: () => {
      },
    },
    {
      index: 7,
      type: 'up',
      key: sortKey.status,
      active: '',
      callback: () => {
      },
    },
    {
      index: 8,
      type: 'up',
      key: sortKey.startDate,
      active: '',
      callback: () => {
      },
    },
    {
      index: 9,
      type: 'up',
      key: sortKey.endDate,
      active: '',
      callback: () => {
      },
    },
  ];

  // クリックされたソートを書き換える
  const sort = useMemo<SortListType[]>(() => {
    return sortList.filter((elm) => elm.index === index);
  }, [sortList]);
  sort[0].type = highlow ? 'down' : 'up';
  sort[0].active = activeSort ? 'active' : '';
  const tableHead: string[] = [
    'ID',
    'カテゴリ',
    '通知レベル',
    '通知形式',
    'タイトル',
    '作成日',
    '作成者',
    '状態',
    '通知開始日',
    '通知終了日',
    '開封状況',
  ];

  const sortParam = useMemo<{[key in string]: string  | number | string[]}>(() => {
    const param: {[key in string]: string | number | string[]} = {};
    if (startDate) param.notification_start_date_from = DateFormatter.str2str(startDate, 'YYYYMMDD', '-') ?? '';
    if (endDate) param.notification_end_date_to = DateFormatter.str2str(endDate, 'YYYYMMDD', '-') ?? '';
    if (createDate) param.created_at = createDate;
    if (freeWord) param.word = freeWord.replace(/( )|(　)/g, '').split(' ');
    if (Number(category)) param.category = Number(category);
    if (level) param.level = Number(level);
    return param;
  }, [startDate, endDate, createDate, freeWord, category, level]);

  const tableBody = useMemo<TableElement[][]>(() => {
    return infoList?.map((v) => [
      v.internal_id ?? '',
      categoryList.find((v2) => Number(v2.key) === v.category)?.label || '',
      levelList.find((v2) => Number(v2.key) === v.level)?.label || '',
      v.is_url ? (
       <div
        onClick={(e) => {
          e.stopPropagation();
          window.open(v.url ?? '');
        }}
       >
         <img
          src={iconLink}
          alt=""
        />
      </div>
      ) : '',
      (v.title?.length > 20 ? v.title.substr(0, 20) + '...' : v.title) || '',
      v.created_at ? DateFormatter.date2str(new Date(v.created_at)) : '',
      (v.created_display_name?.length > 20 ? v.created_display_name.substr(0, 20) + '...' : v.created_display_name) || '',
      (() => {
        switch (v.status) {
          case 1: return '掲載中';
          case 2: return '掲載終了';
          case 3: return '掲載予定';
          default: return ''
        }
      })(),
      v.notification_start_date.split('-').join('/'),
      v.notification_end_date.split('-').join('/'),
      `${v.opened_count}/${v.sent_count}`,
      v.status === 2 ? 'stop' : 'active',
    ]) ?? [];
  }, [infoList, categoryList, levelList]);

  // 表示開始のデータ番号
  const totalPage = useMemo<number>(() => {
    const _totalPage = totalInfo % perData ?
      Math.floor(totalInfo / perData) : // 合計のページ数
      Math.floor(totalInfo / perData) - 1; // 合計のページ数(件数が丁度ページいっぱいで収まる時)
    return _totalPage;
  }, [totalInfo, perData ,infoList]);

  // TODO: 後で通知画面に移植
  // const [orgData, setOrgData] = useState<{ id: string, label: string; }[]>([]);
  // const handleClickOrgDesignation = useCallback(
  //   () => {
  //     if (apiManger.type === 'company') {
  //       CompanyLogOperation('companyUserAddOrganizationSelect', () => {
  //         apiCompany.organizations().get()
  //           .then((res) => {
  //             const orgList = (res as ApiGetOrganizationsResponse).body.data;
  //             console.log('orgList', orgList);
  //             dispatch(dialogAction.push({
  //               title: '組織の指定',
  //               element: <OrganizationSelectDialogInformation
  //                 orgData={orgList as any}
  //                 selectOrg={''}
  //                 callback={(v) => {
  //                   setOrgData(v);
  //                 }}
  //                 mode={'add'}
  //                 selectList={orgData}
  //               />,
  //             }));
  //           });
  //       });
  //     }
  //   }, [orgData]);

  const getCategoryList = useCallback(async () => {
    try {
      const data = await baseApi.informations().categories().get();
      const body = data.body.data;
      const categoryList: SelectData[] = [];
      body.forEach((v) => categoryList.push({ key: v.id.toString() , label: v.name }));
      setCategoryList(categoryList);
    } catch(e) {
      ApiManager.errorFunc(e);
    }
  }, [baseApi]);

  const getLevelList = useCallback(async () => {
    try {
      const data = await baseApi.informations().levels().get();
      const body = data.body.data;
      const levelList: SelectData[] = [];
      body.forEach((v) => levelList.push({ key: v.id.toString() , label: v.name }));
      setLevelList(levelList);
    } catch(e) {
      ApiManager.errorFunc(e);
    }
  }, [baseApi])

  const getInfoList = useCallback(async(v?: {[key in string]: string | number | string[]}, init?: boolean ) => {
    if (queryFlag) {
      const query = QueryParamFormatter.queryParse().p;
      setCurrentPage(query ? Number(query) : currentPage);
    } else {
      setCurrentPage(currentPage);
    }

    const searchParam: {[key in string]: string | number | string[] | undefined} = {...(v ?? sortParam)};
    const keys = Object.keys(searchParam) as (keyof typeof searchParam)[];
    keys.forEach((key) => {
      if (key === 'word' && searchParam.word) {
        const _word = searchParam.word;
        delete searchParam.word;
        (_word as string[]).forEach((v, i) => {
          searchParam[`word[${i}]`] = v;
        })
      }
      if (!searchParam[key]) searchParam[key] = undefined;
    })


    const data: any = !v
    ? await (baseApi as any).informations().get({
        ...searchParam,
        sort_by: sortBy,
        highlow: highlow,
        page: currentPage + 1,
      })
    : await (baseApi as any).informations().get({ 
      sort_by: sortBy,
      highlow: highlow,
      ...searchParam,
     });

    const body: InfosListDate[] = (data.body.data as any)?.data;
    const total: number = (data.body.data as any)?.total;

    setTotalInfo(total);
    if (body.length) {
      if (tableRef.current) {
        tableRef.current.scrollTo(0, 0)
      }
      setInfoList(body);
    } else {
      if (infoList.length) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['検索結果が見つかりませんでした。'],
          buttons: [
            { label: 'OK', callback: () => dispatch(dialogAction.pop())}
          ]
        }))
      }
    }
    setQueryFlag(false);
  }, [sortBy, highlow, currentPage, tableRef, infoList])

  // ソート機能
  useEffect(() => {
    getCategoryList();
    getLevelList();
    getInfoList();
  }, [sortBy, highlow, currentPage]);

  return (
    <div
      id="App"
      className="information_list"
    >
      <SideBar
        currentPage="information-list"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList(apiManger.type)} />
          <div className="inner">
            <section>
              <header>
                <h2>
                  インフォメーション一覧
                </h2>
                <div className="count">
                  <span>件数</span>
                  {/*TODO: APIで取得*/}
                  {isNaN(totalInfo) ? 0 : totalInfo}
                  <span>件</span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    label="新規登録"
                    onClick={onClickAdd}
                  />
                  {/*TODO: 後で通知画面に移植*/}
                  {/*<Button*/}
                  {/*  label="組織指定"*/}
                  {/*  onClick={handleClickOrgDesignation}*/}
                  {/*/>*/}
                </div>
              </header>
              <Accordion
                summary="検索条件"
                contents={
                  <div className="information_list__search">
                    <div className="information_list__search__row">
                      <div className="period_form">
                        <LabeledForm
                          label="通知期間"
                          formEle={
                            <Input
                              type="date"
                              value={startDate || ''}
                              onChange={(e) => {
                                setStartDate(e.target.value || null);
                                getInfoList({...sortParam, notification_start_date_from: e ? 
                                  e.target.value : '',
                                });
                              }}
                              onClickClearDate={() => {
                                setStartDate(null);
                                getInfoList({...sortParam, notification_start_date_from: ''
                                });
                              }}
                              ref={startRef}
                              max={endDate || undefined}
                            />
                            // <DatePicker
                            //   handleChange={(e) => {
                            //     if (e) {
                            //       setStartDate(DateFormatter.date2str(e, 'YYYYMMDD', '-'));
                            //     } else {
                            //       setStartDate('');
                            //     }
                            //     getInfoList({...sortParam, notification_start_date_from: e ? 
                            //       DateFormatter.date2str(e, 'YYYYMMDD', '-') : '',
                            //     });
                            //   }}
                            //   startDate={startDate ? new Date(startDate) : null}
                            //   maxDate={endDate ? new Date(endDate) : undefined}
                            //   _ref={startRef}
                            // />
                          }
                        />
                        <div className="period_form__tilde" />
                        <LabeledForm
                          label=""
                          formEle={
                            <Input
                              type="date"
                              value={endDate || ''}
                              onChange={(e) => {
                                setEndDate(e.target.value || null);
                                getInfoList({...sortParam, notification_end_date_to: e ? 
                                  e.target.value : '',
                                });
                              }}
                              onClickClearDate={() => {
                                setEndDate(null);
                                getInfoList({...sortParam, notification_end_date_to: ''
                                });
                              }}
                              ref={endRef}
                              min={startDate || undefined}
                            />
                          }
                        />
                      </div>
                      <LabeledForm
                        label="作成日"
                          formEle={
                            <Input
                              type="date"
                              value={createDate || ''}
                              onChange={(e) => {
                                setCreateDate(e.target.value || '');
                                getInfoList({...sortParam, created_at: e ? 
                                  e.target.value : '',
                                });
                              }}
                              onClickClearDate={() => {
                                setCreateDate('');
                                getInfoList({...sortParam, created_at: ''
                                });
                              }}
                              ref={createRef}
                            />

                            // <DatePicker
                            // handleChange={(e) => {
                            //   if (e) {
                            //     setCreateDate(DateFormatter.date2str(e, 'YYYYMMDD', '-'));
                            //   } else {
                            //     setCreateDate('')
                            //   }
                            //   getInfoList({...sortParam, created_at: e ? 
                            //     DateFormatter.date2str(e, 'YYYYMMDD', '-') : '',
                            //   });
                            // }}
                            // startDate={createDate ? new Date(createDate) : null}
                            // _ref={createRef}
                          // />

                            // <div className="input_wrap">
                            //   <Input
                            //     type="date"
                            //     value={createDate ?? ''}
                            //     onChange={(e) => {
                            //       setCreateDate(e.target.value);
                            //       getInfoList({...sortParam, created_at: e.target.value});
                            //     }}
                            //   />
                            // </div>
                          }
                      />
                    </div>
                    <div className="information_list__search__row">
                      <LabeledForm
                        label="フリーワード"
                        formEle={<Input
                          placeholder="タイトル、内容、作成者"
                          onChange={(e) => changeFreeWord(e.target.value)}
                          onBlurCapture={(e) => getInfoList({...sortParam, word: e.target.value.replace(/( )|(　)/g, ' ').split(' ')})}
                        />}
                        className="free_word"
                      />
                      <LabeledForm
                        label="カテゴリ"
                        formEle={<Select
                          value={category}
                          list={[{key: '0', label: '指定無し'}].concat(categoryList)}
                          placeholder="指定なし"
                          onChange={(v) => {
                            changeCategory(v as any);
                            getInfoList({...sortParam, category: Number(v) || ''})
                          }}
                        />}
                      />
                      <LabeledForm
                        label="通知レベル"
                        formEle={<Select
                          value={level}
                          list={[{label: '指定無し', key: '0'}].concat(levelList)}
                          placeholder="指定なし"
                          onChange={(v) => {
                            changeLevel(v as any);
                            getInfoList({...sortParam, level: Number(v)});
                          }}
                        />}
                      />
                    </div>
                  </div>
                }

              />
              <div className="table_box">
                <Table
                  _ref={tableRef}
                  head={tableHead}
                  body={tableBody}
                  alignList={['c', 'c', 'c', 'c', 'l', 'c', 'c', 'c', 'c', 'c', 'c']}
                  sortList={sortList}
                  setSortBy={setSortBy}
                  setHighlow={setHighlow}
                  setIndex={setIndex}
                  setActive={setActiveSort}
                  onClickRow={async(i) => {
                    const isCompany = apiManger.type === 'company';
                    const id = infoList[i].info_id;
                    const url = isCompany ? 'companyInfoDetail' : 'adminInfoDetail';
                    isCompany 
                    ? await operation.company('informationListDetailClick')
                    : await operation.isp('informationListDetailClick');

                    dispatch(push(`${RoutingPath[url].replace(':infoId', id)}`));
                  }}
                />
              </div>
              <Pager
                totalPage={totalPage}
                currentPage={currentPage}
                callback={setCurrentPage}
                // onChange={(page) => changePaginate(page)}
                onChange={(page) => {}}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
