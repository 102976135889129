// EditorJS
// https://editorjs.io/api

import { RefObject, useEffect, useRef } from 'react';
import EditorJS, { API, LogLevels, OutputData } from '@editorjs/editorjs';
import { TestInlineTool } from '../models/editor/plugins/test.inline-tool';

const CONTAINER_ID = 'editor-container';
const ColorPlugin = require('editorjs-text-color-plugin');
const Size = require('editorjs-inline-font-size-tool');
const Strikethrough = require('editorjs-strikethrough');
const UnderLine = require('@editorjs/underline');


export const useEditorJs = (ref: RefObject<HTMLDivElement>, onChange: (e: OutputData) => void, 
  option?: {
    initData?: OutputData,
    isReadOnly?: boolean,
  }
) => {
  const editor = useRef<EditorJS | null>(null);
  useEffect(() => {
    if (!(ref && ref.current)) {
      return;
    }
    // - initialize editor.js -
    const container = ref.current;
    container.id = container.id ?? CONTAINER_ID;
    editor.current = new EditorJS({
      holder: container.id,
      readOnly: option?.isReadOnly,
      logLevel: "ERROR" as any,
      inlineToolbar: ['bold', 'italic', 'Size', 'Color', 'Marker', '下線', '取り消し線'],
      onChange: async(e) => {
        const change = (isRetry?: boolean) => {
          const disabled = editor.current?.readOnly.isEnabled;
          if (option?.isReadOnly || disabled) return;
          editor.current?.save().then((result) => {
            if (!result) return;
            if (option?.isReadOnly) return;
            if (((e.blocks.getBlocksCount() ?? 0) !== result.blocks.length) && !isRetry) {
              change(true);
            } else if (result) onChange(result);
          });  
        }
        change();
      },
      // autofocus: true,
      tools: {
        'Size': {
          class: Size,
        },
        'Color' : {
          class: ColorPlugin,
          config: {
            // defaultColor: '#00000000',
            customPicker: true,
            style: true,
            type: 'text',
          }
        },
        'Marker': {
          class: ColorPlugin,
          config: {
            // defaultColor: '#00000000',
            customPicker: true,
            type: 'marker'
          }
        },
        '下線': {
          class: UnderLine,
        },
        '取り消し線': {
          class: Strikethrough,
        },
        link: {
          class: TestInlineTool as any,
          inlineToolbar: true,
          toolbox: false,
        }
      },
    });
    return () => {
      // - destroy editor.js -
    };
  }, [ref]);
  return editor;
};
