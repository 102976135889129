import islogin from './islogin';
import login from './login';
import logout from './logout';
import profiles from './profiles';
import articles from './articles';
import organizations from './organizations';
import password from './password';
import members from './members';
import users from '../data-app/users';
import requests from './requests';
import icon from './icon';
import applications from './applications';
import accounts from './accounts';
import common from './common';
import logs from './logs';
import version from './version';
import idms from './idms';
import con from '../../connection';
import v2 from './v2';
import exclusive from './exclusive';
import informations from './informations';
import contacts from './contacts';
import mfa from './mfa';
var dataApp = function (param) {
    var mode = param.mode, inputHost = param.host;
    // - chose host -
    var host = '';
    switch (mode) {
        case 'local':
            host = 'https://m-loop-data-app.marietta.dev';
            break;
        case 'develop':
            host = '.';
            break;
        case 'stage':
            host = '.';
            break;
        case 'production':
            host = '.';
            break;
    }
    // - path -
    var path = '/api';
    // - url -
    var url = "" + (inputHost || host) + path + '/' + (process.env.REACT_APP_API_VERSION ?? 'v1');
    return ({
        articles: articles(url),
        icon: icon(url),
        islogin: islogin(url),
        login: login(url),
        logout: logout(url),
        members: members(url),
        organizations: organizations(url),
        password: password(url),
        portal: {},
        profiles: profiles(url),
        requests: requests(url),
        users: users(url),
        applications: applications(url),
        accounts: accounts(url),
        common: common(url),
        logs: logs(url),
        version: version(url),
        idms: idms(url),
        v2: v2(url),
        exclusive: exclusive(url),
        informations: informations(url),
        contacts: contacts(url),
        timeoutTest: function (port) { return con.get("http://localhost:" + port + "/timeoutTest", {}); },
        mfa: mfa(url),
    });
};
export default dataApp;
