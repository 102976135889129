import { useCallback, useMemo, useState } from "react"
import { cloneDeep } from 'lodash';

type LogProductRequest = {
  is_cadect: boolean,
  is_masis: boolean,
  is_plan: boolean,
}

type LogProductListData = {
  label: string,
  key: keyof LogProductRequest,
  id: string,
  acceptMode?: 'accept' | 'ignore',
  companyCodeList?: string[],
}

const PRODUCT_LIST: LogProductListData[] = [
  { id: 'cadect', label: 'CADECT', key: 'is_cadect', acceptMode: 'ignore', companyCodeList: ['admin'] },
  // { id: 'd-link', label: 'D-Link', key: 'is_dlink', acceptMode: 'accept', companyCodeList: ['admin'] },
  // { id: 'stabro' ,label: 'STABRO', key: 'is_stabro' },
  // { id: 'a-repo-b', label: 'A-repo4建築', key: 'is_arepo4b' },
  // { id: 'a-repo-h', label: 'A-repo4住宅', key: 'is_arepo4h' },
  { id: 'プラン図', label: 'プラン図', key: 'is_plan' },
  { id: 'MASIS', label: 'MASIS', key: 'is_masis' },
]

type Props = {
  companyCode: string,
  isPortal: boolean,
}

export const useLogProduct = (props: Props) => {
  const { companyCode, isPortal } = props;
  const [list, setList] = useState(PRODUCT_LIST.map((item) => ({
    ...item,
    checked: false,
  })));

  const productList = useMemo(() => {
    return list.filter((data) => {
      if (!data.acceptMode || !data.companyCodeList) return true;
      if (data.acceptMode === 'accept') {
        return data.companyCodeList.includes(companyCode);
      } else {
        return !data.companyCodeList.includes(companyCode);
      }
    });
  }, [companyCode, list]);

  const requestLogProduct = useMemo(() => {
    const validation = list.map((data) => {
      if (
        ((data.acceptMode === 'accept') && !data.companyCodeList?.includes(companyCode))
        || ((data.acceptMode === 'ignore') && data.companyCodeList?.includes(companyCode))
        || isPortal
      ) {
        return { key: data.key, checked: false };
      } else {
        return { key: data.key, checked: data.checked };
      }
    });
    const result: Partial<{[key in keyof LogProductRequest]: boolean }> = {};
    for (const data of validation) {
      result[data.key] = data.checked;
    }
    return result;
  }, [list, companyCode, isPortal]);

  const productCheckToggle = useCallback((key: keyof LogProductRequest) => {
    const clone = cloneDeep(list);
    const find = clone.find((data) => data.key === key);
    if (!find) {
      return;
    }
    find.checked = !find.checked;
    setList(clone);
  }, [list]);

  const initProduct = useCallback(() => {
    setList(PRODUCT_LIST.map((item) => ({
      ...item,
      checked: false,
    })));
  }, []);
  return {
    productList,
    productCheckToggle,
    requestLogProduct,
    initProduct,
  }
}