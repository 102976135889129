import companies from './companies';
import login from './login';
import common from './common';
import islogin from './islogin';
import logout from './logout';
import portals from './portals';
import users from './users';
import logs from './logs';
import profiles from './profiles/index';
import password from './password/index';
import informations from './informations/index';
import master from './master';
var adminWeb = function (param) {
    var mode = param.mode, inputHost = param.host;
    // - chose host -
    var host = '';
    switch (mode) {
        case 'local':
            host = 'https://m-loop-mth-web.marietta.dev';
            break;
        case 'develop':
            host = '.';
            break;
        case 'stage':
            host = '.';
            break;
        case 'production':
            host = '.';
            break;
    }
    // - path -
    var path = '/api';
    // - url -
    var url = "" + (inputHost || host) + path + '/' + (process.env.REACT_APP_API_VERSION ?? 'v1');
    return ({
        common: common(url),
        companies: companies(url),
        islogin: islogin(url),
        login: login(url),
        logout: logout(url),
        logs: logs(url),
        portals: portals(url),
        users: users(url),
        profiles: profiles(url),
        password: password(url),
        informations: informations(url),
        master: master(url),
    });
};
export default adminWeb;
